.form-signin input {
  margin-bottom: 5px;
}

.alert ul {
  list-style-type: none;
}

body {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
}

.btn {
  text-transform: uppercase;
  font-weight: bold;
  font-weight: 300;
}

.form-signin-heading {
  color: #0087ac;
  font-size: 26px;
  text-align: center;
}

.form-signin input {
  font-size: 18px;
}

.btn-primary {
  color: #fff;
  background-color: #0087ac;
  border-color: #007393;
}

.btn-primary:hover {
  color: #fff;
  background-color: #005f79;
  border-color: #004355;
}

.header-logo {
  height: 82px;
}

.header-logo img {
  height: 46px;
  margin: 20px;
}

.header-title {
  background-color: #f0f0f0;
  height: 82px;
  color: #0087ac;
}

.header-title h2 {
  padding: 25px 20px;
  margin: 0;
}